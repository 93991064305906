<template>
  <div v-if="loading">
    <sdCards headless>
      <div class="spin">
        <a-spin />
      </div>
    </sdCards>
  </div>
  <div v-else>
    <sdPageHeader title="All Clients">
      <template v-slot:subTitle>
        <!--<a-button size="default" type="text" style="border: none">
          <h3 style="color: black !important">Favourites</h3>
        </a-button>-->
        <a-button size="default" type="text" style="border: none;">
          <h3>Clients Connected <span style="padding: 6px 10px; background:#2e2a2a; border-radius: 50%; color: #fff;"><b>{{counts}}</b></span></h3>
        </a-button>
        <a-button size="default" type="text" style="border: none;">
          <h3>Sub-Clients Connected <span style="padding: 6px 10px; background:#2e2a2a; border-radius: 50%; color: #fff;"><b>{{counts1}}</b></span></h3>
        </a-button>
        <a-button
          size="default"
          type="text"
          style="
            border: none;
            vertical-align: middle;
            transform: rotateZ(270deg);
          "
        >
          <span>
            <sdFeatherIcons
              style="line-height: 0; color: black !important"
              type="bar-chart"
              size="18"
          /></span>

        </a-button>
       </template>

    </sdPageHeader>
     
    <Main>
    
      <div v-if="empty">
        <sdCards headless>
          <div class="spin">
            <a-spin />
          </div>
        </sdCards>
      </div>
      <div v-else>
        <a-row :gutter="25">
          <a-col :md="24">
            <div headless v-if="empty">
              <div v-if="loading">
                <sdCards headless>
                  <div class="spin">
                    <a-spin />
                  </div>
                </sdCards>
              </div>
            </div>
            <div headless v-else>
              <a-list
                style="margin: 0px"
                class="demo-loadmore-list"
                item-layout="horizontal"
                :data-source="usersTableData"
              >
                <template #renderItem="{ item }">
                  <ProductCard
                    class="list-view"
                    :style="{ marginBottom: '20px' }"
                  >
                    <div class="product-list" style="padding: 10px">
                      <a-row :gutter="15">
                        <a-col :md="2" :xs="24">
                          <figure style="margin-left: 20px">
                            <img
                              :style="{ width: '40px' }"
                              :src="
                                require('@/static/img/auth/Illustration.png')
                              "
                              alt=""
                            />
                          </figure>
                        </a-col>
                        <a-col
                          :md="13"
                          :xs="24"
                          v-on:click="overviewpage(item.xero_user_id)"
                        >
                          <div class="product-single-description">
                            <sdHeading
                              class="product-single-title"
                              as="h2"
                              style="margin: 0px; font-size: 16px"
                            >
                              {{ item.org_name }}
                            </sdHeading>
                            <p style="margin: 0px; font-size: 14px">
                              Sub Clients Connected:
                              {{
                                item.total_subclients_connected
                              }}
                            </p>
                          </div>
                          
                        </a-col>
                        
                        
                      </a-row>
                    </div>
                  </ProductCard>
                </template>
              </a-list>
            </div>
          </a-col>
       </a-row>
       
       </div>

    </Main>
  </div>
</template>
<script>
import { Main, TableWrapper } from "../styled";
import { computed, ref, defineComponent, onMounted,reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { UserTableStyleWrapper, Action, ProductCard } from "./style";
import * as FontAwesomeIcon from "@fortawesome/free-solid-svg-icons";
import Modal from "/src/components/Modal.vue";
import HalfProgressBar from "/src/components/utilities/progressBar.vue";
import { message, Notification } from 'ant-design-vue';

export default defineComponent({
  name: "dashboard",
  components: {
    Main,
    TableWrapper,
    UserTableStyleWrapper,
    Modal,
    HalfProgressBar,
    ProductCard,
    FontAwesomeIcon,
    Action,
    
  },
 
  setup() {
    let empty = ref(true);
    let loading = ref(true);
    var counts =ref(null);
    var counts1 =ref(null);
    let reconnect=ref(false);
    var msg = "";
    const param = useRoute();
    const router = useRouter();
    const { state, dispatch } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    onMounted(() => {
      xeroOrgCheck();
      xerocheck();
      //disconnectData();

    });
     const xeroOrgCheck = () => {

      dispatch("fetchOrgData").then(
        (response) => {     
        },
        (error) => {}
      );
    };
    const xerocheck = () => {
    localStorage.removeItem("search_data");
    localStorage.removeItem("filter_data");
      dispatch("getmainclientlist").then(
          (response) => {
            state.xeroclient.data = response.data;
            counts.value = response.data[0].total_clients_connected;
            counts1.value = response.data[0].total_subclients;
            empty.value = false;
            loading.value = false;
          },
          (error) => {
            empty.value = false;
            loading.value = false;
          }
        );
    };
    const disconnectData = () => {
      dispatch("disconnectData").then(
          (response) => {
            state.xeroclient.data = response.data;
            empty.value = false;
            loading.value = false;
          },
          (error) => {
            empty.value = false;
            loading.value = false;
          }
        );
    };    
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        name: record.name,
      }),
    };
    const overviewpage = (id) => {
      if (id !== undefined) {
        localStorage.setItem("xero_user_id", id);
        router.push({ path: "/subclients" });
      }
    };
  
  
    var usersTableData = computed(() => state.xeroclient.data);

    return {
      rowSelection,
      empty,
      usersTableData,
      overviewpage,
      loading,
      counts,
      counts1,
      disconnectData,
      xeroOrgCheck,
    };
  },
});
</script>
<style>
.ant-list-items {
  padding-top: 0px !important;
}

.highlighted {
  margin: 10px !important;
  border-spacing: 0 8px !important;
}

.product-list:hover {
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
</style>