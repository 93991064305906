<template>
  <sdCards title="Masters" size="default" style="height: 95% !important">
    <div class="notification">
      <ActivityContents>
        <ul class="activity-list">
          <!-- <li class="activity-list__single">
            <div class="activity-content"> 
                <router-link to="dup_contact">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Duplicate Contacts
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Contacts with Similar Name and Email.</li>
                        </ul>
                      </span>
                      </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['duplicate_contacts'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["duplicate_contacts"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>-->
          <!--<li class="activity-list__single">
            <div class="activity-content">
              <router-link to="unuse_chart">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Unused Chart of Accounts
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Gives List of Accounts codes which are not used in any Bank Transaction, Invoice and Manual Journals.</li>
                        </ul>
                      </span>
                      </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['unused_chart_accounts'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["unused_chart_accounts"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>-->
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="repeat_sinvoice">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Repeating Sales Invoice
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Provide a List of all Sales Invoices Repeating in the Report Review Period.</li>
                        </ul>
                      </span>
                      </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['repeating_sales_invoice'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["repeating_sales_invoice"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <li class="activity-list__single">
            <div class="activity-content">
              <router-link to="repeat_pinvoice">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                        Repeating Purchase Invoice
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>Provide a List of all Purchase Invoices Repeating in the Report Review Period.</li>
                        </ul>
                      </span>
                      </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['repeating_purchase_invoice'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["repeating_purchase_invoice"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>
          <!--<li class="activity-list__single">
            <div class="activity-content">
              <router-link to="journal">
                <div class="activity-info">
                  <p>
                    <span class="tooltip1">
                      <h4 style="margin-bottom: 0px !important">
                       Repeating Journal Schedule
                      </h4>
                      <span class="tooltip1__content">
                        <ul>
                          <li>List of all Manual Journals Repeating in the Report Review Period.</li>
                          <li>It also captures probable repeating manual journals which are posted repeatedly in report review period and not posted automatically.</li>
                        </ul>
                      </span>
                      </span
                    >
                  </p>
                </div>
              </router-link>
            </div>
            <template v-if="data['journal_schedule'] != 0">
              <span
                class="activity-icon danger"
                style="margin-left: 10px; margin-right: 10px"
              >
                {{ data["journal_schedule"] }}
              </span>
              <span class="activity-icon danger">
                <sdFeatherIcons type="alert-circle" size="24" />
              </span>
            </template>
            <template v-else>
              <span class="activity-icon success">
                <sdFeatherIcons type="check-circle" size="24" />
              </span>
            </template>
          </li>-->
        </ul>
      </ActivityContents>
    </div>
  </sdCards>
</template> 
<script>
import { computed, ref, defineComponent, onMounted } from "vue";
import {
  UserTableStyleWrapper,
  ActivityContents,
  ProductCard,
} from "../../../style";

export default defineComponent({
  name: "Masters",
  props: ["data"],
  watch: {
    data: function (newVal, oldVal) {
      // watch it
      // console.log(newVal);
      // console.log(oldVal);
    },
  },
  components: { ActivityContents },

  setup() {
    return {};
  },
  methods: {},
});
</script>
